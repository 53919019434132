
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { computed, defineComponent, nextTick, onMounted, reactive, ref, toRef, watch } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, SpanMethod, TableColumns } from '@/types/BasicComponent'
import { formatTime, formatDate, getAreaListOfGx, getTableColumnsSummary } from '@/utils/common'
import dayjs from 'dayjs'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { useRouter } from 'vue-router'
import { createProductionData, createProductionDataByList, getProductionList, ProductionListQueryParams, updateProductionFinishLock } from '@/api/production'
import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport
  },
  setup() {
    const router = useRouter()
    const pagination = usePagination()
    const searchData = reactive<ProductionListQueryParams>({
      dataType: 1
    })
    const tableData = ref<AnyArray>([])
    const gxAreaList = reactive<AnyArray>([])
    const exportRef = ref()
    const exportData = ref<AnyArray>([])

    const getTableData = async () => {
      const resp = await getProductionList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      const respData = resp.data.data.records

      tableData.value = respData
      tableData.value.forEach((td, idx) => td.index = pagination.pageOffset + idx)

      pagination.total = resp.data.data.total
    }

    const getExportData = async () => {
      const resp = await getProductionList(Object.assign({ isExport: 1, pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      exportData.value = resp.data.data.records
    }

    pagination.setCallback(getTableData)

    const selectedRowIDs = ref<number[]>([])
    const selectCount = computed(() => selectedRowIDs.value.length)
    const onSelectionChange = (selection: AnyArray) => (selectedRowIDs.value = selection.map((item: AnyObject) => item.orderid))
		
    onMounted(() => {
      getTableData()
      getAreaListOfGx(gxAreaList) /*已在函数内部添加子项*/
    })

    const invoiceStatusList = ref<number[]>([])
    watch(invoiceStatusList, sList => {
      searchData.invoiceStatusStr = sList.join(',')
    })

    const searchInputs = reactive<Inputs>([
      {
        label: '货单号',
        type: 'input',
        placeholder: '货单号',
        model: toRef(searchData, 'orderNo'),
        width: '180px',
        clearable: true,
      }, {
        label: '胶合板厂',
        type: 'input',
        placeholder: '请输入胶合板厂',
        model: toRef(searchData, 'jhbcName'),
        width: '150px',
        clearable: true
      }, {
        label: '生产机主',
        type: 'input',
        placeholder: '请输入生产机主',
        model: toRef(searchData, 'xqjzName'),
        width: '150px',
        clearable: true
      }, {
        label: '支付状态',
        type: 'select',
        placeholder: '请选择支付状态',
        model: toRef(searchData, 'payStatus'),
        width: '150px',
        clearable: true,
        options: [
          { label: '未支付', value: 1 },
          { label: '已支付', value: 2 }
        ]
      }, {
        label: '开票状态',
        type: 'select',
        placeholder: '请选择开票状态',
        model: invoiceStatusList,
        width: '180px',
        clearable: true,
        multiple: true,
        options: [
          { label: '未开票', value: 1 },
          { label: '已开票', value: 2 },
          { label: '已申请开票', value: 3 }
        ]
      }, {
        label: '生产数据',
        type: 'select',
        placeholder: '请选择生产数据',
        model: toRef(searchData, 'dataType'),
        width: '150px',
        clearable: true,
        options: [
          { label: '全部', value: '' },
          { label: '有生产数据', value: 1 },
          { label: '无生产数据', value: 2 },
        ]
      }, {
        label: '下单时间',
        type: 'group',
        divider: '至',
        inputs: [
          {
            type: 'date',
            model: toRef(searchData, 'orderTimeStart'),
            clearable: false,
            placeholder: '开始日期',
            width: '150px',
          }, {
            type: 'date',
            model: toRef(searchData, 'orderTimeEnd'),
            clearable: false,
            placeholder: '结束日期',
            width: '150px'
          }
        ]
      }, {
        label: '开票时间',
        type: 'group',
        divider: '至',
        inputs: [
          {
            type: 'date',
            model: toRef(searchData, 'invoiceTimeStart'),
            clearable: false,
            placeholder: '开始日期',
            width: '150px',
          }, {
            type: 'date',
            model: toRef(searchData, 'invoiceTimeEnd'),
            clearable: false,
            placeholder: '结束日期',
            width: '150px'
          }
        ]
      }, {
        label: '销售时间',
        type: 'group',
        divider: '至',
        inputs: [
          {
            type: 'date',
            model: toRef(searchData, 'sellTimeStart'),
            clearable: false,
            placeholder: '开始日期',
            width: '150px',
          }, {
            type: 'date',
            model: toRef(searchData, 'sellTimeEnd'),
            clearable: false,
            placeholder: '结束日期',
            width: '150px'
          }
        ]
      }, {
        label: '区域',
        type: 'select',
        placeholder: '请选择区域',
        clearable: true,
        options: gxAreaList,
        model: toRef(searchData, 'areaCode')
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: async () => {
          await getExportData()
          nextTick(() => exportRef.value.doExport())
        }
      }, {
        label: '批量生成',
        icon: 'el-icon-refresh',
        onClick: async () => {
          const selectedOrderIds = selectedRowIDs.value
          if (selectedOrderIds.length === 0) {
            ElMessage.warning('请选择需要批量生成的数据')
            return
          }
          await createProductionDataByList(selectedOrderIds)
          await getTableData()
          ElMessage.success('已批量生成')
        }
      }, {
        label: '锁定订单',
        icon: 'el-icon-lock',
        onClick: () => {
            const selectedOrderIds = selectedRowIDs.value
            if (selectedOrderIds.length === 0) {
              return !ElMessageBox.alert('请勾选需要锁定的订单', '提示', {
                confirmButtonText: '确定',
                type: 'warning'
              })
            }
              
            ElMessageBox.confirm('锁定 ' + selectedOrderIds.length + ' 条订单', '提示', {
                confirmButtonText: '锁定',
                cancelButtonText: '取消',
                type: 'info'
            }).then(async () => {
              await updateProductionFinishLock(selectedOrderIds)
              ElMessage.success('订单已锁定')
              await getTableData()
            }).catch(() => {})
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        label: '多选',
        width: '70px',
        align: 'center',
        type: 'selection',
        selectable: (row: any, index: number) => (row.isFinish !== 1)
      }, {
        prop: 'index',
        label: '序号',
        width: '60px',
        align: 'center'
      }, {
        prop: 'xqjName',
        label: '旋切机主',
        minWidth: '100px'
      }, {
        prop: 'phone',
        label: '手机号',
        minWidth: '150px',
        preventFormat: true
      }, {
        prop: 'idcard',
        label: '身份证号',
        minWidth: '200px',
        preventFormat: true
      }, {
        prop: 'jhbName',
        label: '胶合板厂',
        minWidth: '250px'
      }, {
        prop: 'orderCode',
        label: '货单号',
        minWidth: '200px',
        preventFormat: true
      }, {
        prop: 'createdtime',
        label: '下单时间',
        minWidth: '120px',
        type: 'render',
        render: scope => formatTime(scope.row.createdtime)
      }, {
        prop: 'loadtime',
        label: '发车时间',
        minWidth: '120px',
        type: 'render',
        render: scope => formatTime(scope.row.loadtime)
      }, {
        prop: 'unloadtime',
        label: '卸车时间',
        minWidth: '120px',
        type: 'render',
        render: scope => formatTime(scope.row.unloadtime)
      }, {
        prop: 'goodsName',
        label: '商品',
        minWidth: '100px',
      }, {
        prop: 'woodPrice', //unit_price
        label: '单价',
        minWidth: '100px',
      }, {
        prop: 'volume',
        label: '数量',
        minWidth: '100px',
      }, {
        prop: 'electricCost',
        label: '电费',
        minWidth: '100px',
      }, {
        prop: 'byContractCost',
        label: '承包费',
        minWidth: '100px',
      }, {
        prop: 'deviceCost',
        label: '设备租赁费',
        minWidth: '100px',
      }, {
        prop: 'woodCost',
        label: '原木采购费',
        minWidth: '120px',
      }, {
        prop: 'totalprice',
        label: '总价',
        minWidth: '120px',
      }, {
        prop: 'paytime',
        label: '支付时间',
        minWidth: '120px',
        type: 'render',
        render: scope => formatTime(scope.row.paytime)
      }, {
        prop: 'invoiceDate',
        label: '开票时间',
        minWidth: '100px',
        type: 'render',
        render: scope => formatDate(scope.row.invoiceDate)
      }, {
        prop: 'payStatus',
        label: '付款状态',
        minWidth: '100px',
        type: 'status',
        align: 'center',
        statusType: [
          { label: '未支付', value: 1, type: 'info' },
          { label: '已支付', value: 2, type: 'success' },
          { label: '支付失败', value: 3, type: 'danger' },
          { label: '支付失败', value: 4, type: 'danger' }
        ]
      }, 
      // {
      //   prop: 'invoice_Status',
      //   label: '开票状态',
      //   minWidth: '130px',
      //   type: 'status',
      //   align: 'center',
      //   statusType: [
      //     { label: '未开票', value: 1, type: 'info' },
      //     { label: '已开票', value: 2, type: 'success' },
      //     { label: '已申请开票', value: 3, type: 'warning' },
      //   ]
      // }, 
      {
        type: 'button',
        label: '操作',
        // show: () => selectedPan.value === TabPane.CHECKED,
        fixed: 'right',
        width: '215px',
        align: 'center',
        buttons: [
          {
            label: '详情',
            onClick: scope => router.push({ 'name': 'ProductionProcess', query: { orderId: scope.row.orderid } })
          }, {
            label: scope => {
              if(scope.row.produceTime == null){
                return '生成'
              //}else if(scope.row.is_finish===1){
                //return '取消锁定' //订单已锁定
              }else{
                return '重新生成'
              }
            },
            type: 'primary',
            onClick: async scope => {
              if(scope.row.isFinish===1){
                ElMessageBox.alert('订单已锁定', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning'
                })
              }else{
                await createProductionData(scope.row.orderid)
                ElMessage.success('生成成功')
              }
            }
          }
        ]
      }
    ])
		
    const getRowClassName = (scope: AnyObject) => {
      if(selectedRowIDs.value.indexOf(scope.row.orderid) >= 0){
        return 'current-row' //高亮选中行，这个是 element-ui 自带CSS类名
      }else if(scope.row.isFinish===1){
        return 'is_locked__row'
      }
      return ''
    }
		
    const mySummaryMethod = (scope: AnyObject) => {
      let props = ['volume', 'electricCost', 'byContractCost', 'deviceCost', 'woodCost', 'totalprice']
      return getTableColumnsSummary(props, scope.columns, scope.data)
    }
    
    return {
      getTableData,
      formatTime,
      formatDate,
      onSelectionChange,
      exportRef,
      searchInputs,
      searchData,
      searchButtons,
      tableColumns,
      pagination,
      tableData,
      exportData,
      UserRole,
      getRowClassName,
      selectCount,
      mySummaryMethod
    }
  }
})
