import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-755f4b72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-counter" }
const _hoisted_2 = { style: {"color":"#409EFF"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicTablePrintAndExport = _resolveComponent("BasicTablePrintAndExport")!
  const _component_BasicTablePage = _resolveComponent("BasicTablePage")!

  return (_openBlock(), _createBlock(_component_BasicTablePage, {
    searchInputs: _ctx.searchInputs,
    searchButtons: _ctx.searchButtons,
    tableColumns: _ctx.tableColumns,
    tableData: _ctx.tableData,
    pagination: _ctx.pagination,
    tableRowClassName: _ctx.getRowClassName,
    summaryMethod: _ctx.mySummaryMethod,
    tableRowKey: "orderid",
    onSelectionChange: _ctx.onSelectionChange
  }, {
    dialog: _withCtx(() => [
      _createVNode(_component_BasicTablePrintAndExport, {
        ref: "exportRef",
        tableColumns: _ctx.tableColumns,
        tableData: _ctx.exportData,
        fileName: `生产列表`
      }, null, 8, ["tableColumns", "tableData"])
    ]),
    paginationPrefix: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode("已选 "),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.selectCount), 1),
        _createTextVNode(" 条，")
      ])
    ]),
    _: 1
  }, 8, ["searchInputs", "searchButtons", "tableColumns", "tableData", "pagination", "tableRowClassName", "summaryMethod", "onSelectionChange"]))
}